import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;

import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

import 'tablesaw/dist/tablesaw.jquery';
import libs from './lib/dependencies';
import Swiper from 'swiper';
window.libs = libs;

window.jQuery = $;
require('jquery-viewport-checker');
var Cookies = require('js-cookie');

import * as basicScroll from 'basicscroll';

$(document).foundation();

libs.AOS.init();


// SVG Injector
// Elements to inject
var mySVGsToInject = document.querySelectorAll('img.inject-me');

// Options
var injectorOptions = {
  evalScripts: 'once',
  pngFallback: 'assets/png'
};

var afterAllInjectionsFinishedCallback = function (totalSVGsInjected) {
  // Callback after all SVGs are injected
  console.log('We injected ' + totalSVGsInjected + ' SVG(s)!');
};

var perInjectionCallback = function (svg) {
  // Callback after each SVG is injected
  console.log('SVG injected: ' + svg);
};

// create injector configured by options
var injector = new libs.svgInjector(injectorOptions);

// Trigger the injection
injector.inject(
  mySVGsToInject,
  afterAllInjectionsFinishedCallback,
  perInjectionCallback
);


// Custom scripts
$('.hero__grid-item').hover(function(){
    $(this).parent().attr('data-active', $(this).index());
}, function(){
    $(this).parent().attr('data-active', '');
})

//Hero link class
$('.hero__grid-item a').each(function(){
    if($(this).attr("href").indexOf("#") == 0 || $(this).attr("href").indexOf("#") == 1) $(this).addClass('scroll-link');
});

//Scroll
$('.hero__scroll').click(function(e){
    e.preventDefault();
    var target = $(this).parents('.hero').next();
    $("html, body").animate({ scrollTop: target.offset().top }, 1000);
});

$('.scroll-link').click(function(e){
    e.preventDefault();

    if($(this).hasClass('scroll-link--contact')) return;

    var target = $(this).attr('href');
    $("html, body").animate({ scrollTop: $(target).offset().top }, 1000);
})

$('.scroll-link--contact').click(function(e){
    e.preventDefault();
    var target = $("#contact");
    $("html, body").animate({ scrollTop: $(target).offset().top }, 1000);
})


//Map
$('.map__tooltip').each(function(){
    if(parseInt($(this).css('left')) / parseInt($('.map__tooltips').width()) < 0.5) $(this).addClass('map__tooltip--right');
});

$('.map__tooltip-trigger').click(function(){
    if($(this).parents('.map__tooltip').hasClass('active')) {
        $(this).parents('.map__tooltip').removeClass('active');
    } else {
        $('.map__tooltip').removeClass('active');
        $(this).parents('.map__tooltip').addClass('active');
    }
});

//Viewport Checker
$('.hero--home').viewportChecker({
    callbackFunction: function(elem, action) {
        setTimeout(function(){
            $('.hero--home').addClass('hero--loaded');
        }, 1000);
    }
});
$('.hero--page').viewportChecker();
$('.js-scroll-reveal').viewportChecker();
$('.js-scroll-reveal--top').viewportChecker({
    offset: "-10%"
});

//Boxes fix
setInterval(function(){
    $(window).resize();
},100);

//Paralax
const pxBoxes = []

// Create an animation for each ease box. Each with a different timing.
document.querySelectorAll('.image__bg').forEach((elem, i) => {

  // Crate an instance for the current element and store the instance in an array.
  // We start the animation later using the instances from the array.
  pxBoxes.push(basicScroll.create({
    elem: elem,
    from: 'top-bottom',
    to: 'bottom-top',
    direct: true,
    props: {
      '--ty': {
        from: '25%',
        to: '-25%'
      }
    }
  }))

})
pxBoxes.forEach((easeBox) => easeBox.start());

//Show Contact After scroll
$(window).scroll(function(){
    var height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if(scrollTop > height) $('.request').addClass('active');

    if(scrollTop > $('#contact').offset().top - height)  $('.request').removeClass('active');
});

//Mobile Menu
$('.header__mobile-toggle').click(function(e){
    e.preventDefault();
    $('.header__mobile-menu').toggleClass('active');
})

//File upload
$('.form-upload').click(function(e){
    e.preventDefault();

    var fileField = $('.contact__form .uploader-upload input[type="file"]');
    if($(this).hasClass('form-upload--delete')) {
        fileField.val("").change();
    } else {
        fileField.click();
    }
});

$('.contact__form .uploader-upload input[type="file"]').bind('change focus click', function() {
    console.log('Chanhe!');
    var file = $(this).val();
    var button = $(this).parents('.contact__form').find('.form-upload');
    if($(this).val()) {
        button.text($(this).val().replace("C:\\fakepath\\", "")).addClass('form-upload--delete');
    } else {
        button.text("Upload file").removeClass('form-upload--delete');
    }

});

/* Download Modal */
function isEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

function validateModal(){
    var name = $('#modal-name').val();
    var email = $('#modal-mail').val();
    var checkbox = $("#modal-agree").is(':checked');

    if(name.length > 0 && isEmail(email) && checkbox) return true;
    else return false;
}

$('#download-modal input').on("change click keyup", function(){
    if(validateModal()) {
        $('#download-modal button').removeClass('disabled');
    } else {
        $('#download-modal button').addClass('disabled');
    }
});

$('#modal-submit').click(function(){
    if($(this).hasClass('disabled')) return;

    var name = $('#modal-name').val();
    var email = $('#modal-mail').val();

    $('.contact__form .form-name').val(name);
    $('.contact__form .form-email').val(email);
    $('.contact__form input[type="checkbox"]').prop('checked', true);

    $('.contact__form input[type="submit"]').click();
});


$('a[href*=".pdf"], a[href*=".zip"]').click(function(e){
    if(Cookies.get('download-form-submited') == 'true') {
        return;
    } else {
        e.preventDefault();
        Cookies.set('download-form-file', $(this).attr("href"));
        $('#download-modal').foundation('open');
    }
});

if(Cookies.get('download-form-submited') == 'true' && Cookies.get('download-form-file')) {
    var file = Cookies.get('download-form-file');
    Cookies.remove('download-form-file');
    window.location = file;
}

// Team Grid
$('.team__grid-box-content a').each(function(){
    $(this).html($(this).text().replace(/@.*$/, '<span class="after">$&</span>'));
});
var teamDescription = $('.team__grid-descripion').detach();
function calculateDescriptionPosition(clicked) {
    var nextBoxes = clicked.parent('.column').nextAll('.column');
    var clickedTop = clicked.offset().top;
    var insertAfter = clicked.parent();
    if(nextBoxes.length > 0) {
        nextBoxes.each(function(){
            if($(this).offset().top == clickedTop) insertAfter = $(this);
        })
    }

    return insertAfter;
}
$('.team__grid-box').click(function(){
    var $this = $(this);
    if($(this).hasClass('active')) {
        $(this).removeClass('active');
        teamDescription.slideUp();
    } else {
        $('.team__grid-box').removeClass('active');
        $(this).addClass('active');
        teamDescription.slideUp(500, function(){
            teamDescription.detach().insertAfter(calculateDescriptionPosition($this));
            teamDescription.find('.team__grid-descripion-content').html($this.find('.team__grid-box-description').html());
            teamDescription.slideDown();
        });
    }
});

// Sliders
$('.video-slider').each(function (index) {
    var slider = $(this).find('.swiper-container');
    var next = $(this).find('.swiper-button-next');
    var prev = $(this).find('.swiper-button-prev');

    var videoSwiper = new Swiper(slider[0],{
        spaceBetween: 20,
        slidesPerView: 1,
        navigation: {
            nextEl: next,
            prevEl: prev,
        },
        breakpoints: {
            600: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            1150: {
                spaceBetween: 60,
                slidesPerView: 3,
            }
        }
    });
});


/* DEV ONLY
$('#download-modal input[type="submit"]').click(function(){
    Cookies.set('download-form-submited', 'true');
});
*/
